<template>
  <h1><strong>Serviços</strong></h1>
  <p>Atendendo Indaiatuba e região, Além dos serviços de guincho e resgate automotivo em Indaiatuba, interior de São Paulo, a empresa de Ney Guincho oferece:</p>
  <ul>
    <li><strong>Remoção de veículos com pane ou colisão</strong></li>
    <li><strong>Transporte de veículos novos e antigos</strong></li>
    <li><strong>Transporte de veículos elètricos</strong></li>
    <li><strong>Transporte de equipamentos e máquinas elevatórias</strong></li>
    <li><strong>Transporte de quadriciclos e jetski</strong></li>
    <li><strong>Transportes de veículos leves e utilitários</strong></li>
  </ul>
</template>
<style lang="scss" scoped>
*{
  margin-bottom: 1rem!important;
}
li{
  text-align: left!important;
  padding-left: 1rem!important;
}
</style>
